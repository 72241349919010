import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/24/outline';
import Header from '../../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import InfoCard from './InfoCard';
import { formatDateTime } from '../../../../helpers/DateHelpers';
import ApiCard from '../API/ApiCard';
import WebhookEndpointCard from '../Webhook/EndpointCard';

export default function SimpleView({ connection }) {
  const navigate = useNavigate();

  const buttons = [
    { label: 'Edit', icon: PencilIcon, onClick: () => navigate('edit') },
  ];

  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      <Header
        description={`Added on ${formatDateTime(connection.inserted_at)}.`}
        name={connection.name}
        buttons={buttons}
        avatar={connectionAvatar(connection, { size: 'lg' })}
      />
      <div className="mt-8 max-w-3xl lg:max-w-7xl">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 ">
          <InfoCard connection={connection} />
          <ApiCard connection={connection} />
          <WebhookEndpointCard connection={connection} />
        </div>
      </div>
    </div>

  );
}

SimpleView.propTypes = {
  connection: ConnectionShape.isRequired,
};
