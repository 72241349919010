/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {  PiPlugs } from 'react-icons/pi';
import WorkflowShape from '../../../shapes/WorkflowShape';
import useMe from '../../../context/CurrentUserContext';
import LinkButton from '../../../components/buttons/LinkButton';
import Workflow from './Workflow';
import MidDot from '../../../components/MidDot';
import Tooltip from '../../../components/Tooltip';

export default function WorkflowIndexPageBody({ items }) {
  const { actions } = useMe();
  const [expanded, setExpanded] = useState([]);

  return (
    <tbody className="divide-y divide-gray-300 bg-white">
      {items.map(
        (item) => {
          const workflowActions = actions.filter((a) => a.workflow_id === item.id);
          const inactiveActions = workflowActions.filter((a) => a.status === 'inactive');

          return (
            <Fragment key={`${item.id}`}>
              <tr className={classNames(expanded.includes(item.id) ? 'border-t' : null)}>
                <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm text-gray-900">
                  {item.name}
                </td>
                <td className="hidden whitespace-nowrap px-10 py-3 text-right text-sm text-gray-500 lg:table-cell">
                  <div className="flex">
                    {workflowActions.length}
                    {
                      inactiveActions.length
                        ? (
                          <Tooltip
                            id={`workflow-${item.id}`}
                            icon={<PiPlugs size={14} className="text-red-700 my-0.5 mx-1" />}
                            tooltipPlace="right"
                            className="ml-1"
                            content="Contains inactive actions"
                          />
                        )
                        : null
                    }
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                  {
                    workflowActions.length
                      ? (
                        <>
                          <LinkButton buttonText={expanded.includes(item.id) ? 'Retract' : 'Expand'} onClick={() => setExpanded(_.xor(expanded, [item.id]))} />
                          <MidDot />
                        </>
                      )
                      : null
                  }
                  <LinkButton buttonText="Details" path={item.id} />
                </td>
              </tr>
              {
                expanded.includes(item.id)
                  ? (<Workflow workflowActions={workflowActions} />)
                  : null
              }
            </Fragment>
          );
        },
      )}
    </tbody>
  );
}

WorkflowIndexPageBody.propTypes = {
  items: PropTypes.arrayOf(WorkflowShape).isRequired,
};
