import React, { useEffect } from 'react';
import Input from '../../../../../components/forms/Input';
import FormShape from '../../../../../shapes/FormShape';

export default function BearerSubForm({ form }) {
  useEffect(() => {
    if (!form.watch('api.authentication.token')) {
      form.setValue(
        'api.authentication',
        {
          record_type: 'bearer',
          token: '',
        },
      );
    }
  }, []);
  return (
    <div className="mt-2">
      <div className="mb-4">
        <Input
        // displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
        // defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
          form={form}
          label="Token"
          name="api.authentication.token"
          placeholder="Token..."
        />
      </div>
    </div>
  );
}

BearerSubForm.propTypes = {
  form: FormShape.isRequired,
};
