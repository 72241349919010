/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cronstrue from 'cronstrue';
import { GoDatabase } from 'react-icons/go';
import { Tooltip } from 'react-tooltip';
import { ClockIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../shapes/ConnectionShape';
import ActionShape from '../../../shapes/ActionShape';
import useMe from '../../../context/CurrentUserContext';
import Workflow from '../../Workflow/IndexPage/Workflow';
import StatusBadge from '../Components/StatusBadge';
import LinkButton from '../../../components/buttons/LinkButton';

export default function Body({ items }) {
  const [expanded, setExpanded] = useState([]);

  return (
    <tbody className="divide-y divide-gray-300 bg-white">
      {
        items.map((item) => (
          <Row
            key={item.id}
            item={item}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ))
      }
    </tbody>
  );
}

Body.propTypes = {
  items: PropTypes.arrayOf(ConnectionShape).isRequired,
};

function Row({ item, expanded, setExpanded }) {
  const {
    workflows, connections, actions,
  } = useMe();
  const workflow = workflows.find((w) => w.id === item.workflow_id);
  const source = connections.find((w) => w.id === item.source_connection_id);
  const destination = connections.find((w) => w.id === item.destination_connection_id);
  const workflowActions = workflow ? actions?.filter((a) => workflow.id === a.workflow_id) : [];

  return (
    <>
      <tr>
        <td className="w-full max-w-0 py-2 pl-4 pr-3 text-xs font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
          <div className="ml-3">
            <div className="font-medium text-gray-700">
              {item.name || item.id}
              {item.template ? <span className='ml-2 p-0.5 text-yellow-500 text-xs border border-yellow-500 rounded'>Template</span> : null}
            </div>
            <div className="text-gray-500 text-xs flex">
              {workflow?.name || '**No Workflow**'}
            </div>
          </div>
          <dl className="font-normal lg:hidden">
            <dt className="sr-only">Asdf</dt>
            <dd className="mt-1 truncate text-gray-500 text-xs">Asdf</dd>
          </dl>
        </td>
        <td className="hidden px-3 py-2 text-xs text-gray-500 lg:table-cell">
          <div className="font-medium text-gray-900 flex">
            {
              item.schedule
                ? <ClockIcon className="m-0.5 w-4 h-4 bg-gray-100 border border-gray-300 rounded text-indigo-700" />
                : connectionAvatar(source, { bgColor: 'bg-gray-100 mt-0.5', size: 'xxs' })
            }
            <span className="ml-1">{item.schedule ? 'Scheduled' : source?.name}</span>
            <StatusBadge action={item} />
          </div>
          <div className="text-gray-500 text-xs">
            {item.schedule ? cronstrue.toString(item.schedule) : item.topic }
          </div>
        </td>
        <td className="hidden px-3 py-2 text-xs text-gray-500 lg:table-cell">
          <div className="font-medium text-gray-900 flex">
            {connectionAvatar(destination, { bgColor: 'bg-gray-100 mt-0.5', size: 'xxs' })}
            <span className="ml-1">{destination?.name}</span>
            <span>
              {
                destination?.database
                  ? (
                    <>
                      <Tooltip id={destination?.id} content="Database Connection" />
                      <span data-tooltip-id={destination?.id}>
                        <GoDatabase size={14} className="text-gray-400 my-1 mx-2" />
                      </span>
                    </>
                  )
                  : null
              }
            </span>
          </div>
          <div className="text-gray-500 text-xs flex">
            {
                item.configuration.url?.startsWith('{{destination_connection.api.url}}')
                  ? (
                    <>
                      <Tooltip id={`${item.id}-base`} content={item.configuration.url} />
                      <span data-tooltip-id={`${item.id}-base`}>
                        ...
                      </span>
                    </>
                  )
                  : null
              }
            {item.configuration.url?.replace('{{destination_connection.api.url}}', '')}
          </div>
        </td>
        <td className="py-2 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
          {
            workflowActions.length
              ? <LinkButton buttonText={expanded.includes(item.id) ? 'Retract' : 'Expand'} onClick={() => setExpanded(_.xor(expanded, [item.id]))} />
              : null
          }
          <LinkButton path={item.id} className="text-indigo-600 hover:text-indigo-900 ml-2 text" buttonText="Details" />
        </td>
      </tr>
      {
        expanded.includes(item.id)
          ? (<Workflow workflowActions={workflowActions} highlightId={item.id} />)
          : null
      }
    </>
  );
}

Row.propTypes = {
  item: ActionShape.isRequired,
  setExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.arrayOf(PropTypes.string).isRequired,
};
