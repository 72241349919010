import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BiFilterAlt } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import LinkButton from '../../../../components/buttons/LinkButton';
import UrlPreview from '../../../../components/UrlPreview';
import useMe from '../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import ActionShape from '../../../../shapes/ActionShape';
import StatusBadge from '../../../Action/Components/StatusBadge';
import ThisActionBadge from '../../../Action/Components/ThisActionBadge';

export default function ActionsList({ callbackActions, workflowActions, highlightId }) {
  const { allConnections } = useMe();
  return (
    <ul>
      {
        callbackActions.map((action, activityItemIdx) => {
          // eslint-disable-next-line max-len
          const destinationConnection = allConnections.find((c) => c.id === action.destination_connection_id);
          // eslint-disable-next-line max-len
          const currentCallbackOptions = workflowActions.filter((a) => a.topic === action.after_action?.topic);
          return (
            <li key={action.id} className={classNames('relative pt-3')}>
              <div className="flex gap-x-4">
                <div
                  className={classNames(
                    activityItemIdx === callbackActions.length - 1 ? 'h-6' : '-bottom-6',
                    'absolute left-0 top-0 flex w-12 justify-center border-l ml-6 border-gray-300',
                  )}
                />
                <div className="flex">
                  <div
                    className={classNames(
                      action.matcher !== 'true' ? 'w-4' : 'w-6',
                      'h-3.5 border-b border-l border-gray-300 ml-6',
                    )}
                  />
                  {
                    action.matcher !== 'true'
                      ? (
                        <>
                          <div className="w-4 h-4 border mt-1.5 border-gray-300 rounded flex items-center justify-center">
                            <Tooltip id={`${action.id}-matcher`} content={action.matcher} />
                            <span data-tooltip-id={`${action.id}-matcher`}>
                              <BiFilterAlt className="text-indigo-600 h-3 w-3" />
                            </span>
                          </div>
                          <div className="w-4 h-3.5 border-b border-gray-300" />
                        </>
                      )
                      : null
                  }
                  <div className="border rounded border-gray-300 w-full p-2">
                    { highlightId === action.id ? <ThisActionBadge /> : null }
                    <div className="flex justify-between">
                      <div className="flex">
                        <p className="mr-1 text-xs">Action:</p>
                        <LinkButton buttonText={action.name} className="text-xs mb-1" path={`/actions/${action.id}`} />
                      </div>
                      <StatusBadge action={action} />
                    </div>
                    <div className="text-xs ml-3 flex">
                      <span className="mr-1">
                        {connectionAvatar(destinationConnection, { size: 'xxs' })}
                      </span>
                      <UrlPreview
                        method={action.configuration.method}
                        url={action.configuration.url}
                        connection={destinationConnection}
                      />
                    </div>
                    {/* <p className="font-semibold text-red-600 m-1 text-xs ml-3">
                      Reponse Event:
                      {' '}
                      {action.after_action?.topic}
                    </p> */}
                  </div>
                </div>
              </div>
              {
              currentCallbackOptions.length
                ? (
                  <div className="ml-12">
                    <ActionsList
                      callbackActions={currentCallbackOptions}
                      workflowActions={workflowActions}
                      highlightId={highlightId}
                    />
                  </div>
                )
                : null
              }
            </li>
          );
        })
}
    </ul>
  );
}

ActionsList.propTypes = {
  callbackActions: PropTypes.arrayOf(ActionShape).isRequired,
  workflowActions: PropTypes.arrayOf(ActionShape).isRequired,
};
