/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import ConnectionShape from '../../../shapes/ConnectionShape';
import useClient from '../../../hooks/useClient';
import Modal from '../../../components/Modal';
import LinkButton from '../../../components/buttons/LinkButton';
import { formatDateTime } from '../../../helpers/DateHelpers';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Loading from '../../../components/Loading';
import LogDiffModal from '../../../components/LogDiffModal';

export default function Logs({ connection }) {
  const [currentLog, setCurrentLog] = useState(null);

  const { data: initialLogs, isLoading: isLoadingLogs } = useClient('logs').list({
    records: { connection_id: connection.id }, page_size: 100,
  });

  const logs = initialLogs?.filter((l) => l.topic === 'connection.created' || l.topic === 'connection.updated');

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Connection History
            </h2>
          </div>
          {
            currentLog
              ? (
                <Modal maxWidth=":w-full" open onClose={() => setCurrentLog(null)} fullScreen>
                  <LogDiffModal
                    log={{ ...currentLog, updateLogs: logs }}
                    onClose={() => setCurrentLog(null)}
                  />
                </Modal>
              )
              : null
          }
          {
          isLoadingLogs
            ? (<Loading />)
            : (
              <div className="mt-4 border-b border-gray-300">
                <table className="min-w-full divide-y divide-gray-300 bg-gray-50">
                  <thead className="bg-white">
                    <tr>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Topic
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Timestamp
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Details</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300 bg-white">
                    {logs.map((log) => (
                      <tr key={log.id}>
                        <td className="text-gray-600 whitespace-nowrap px-3 py-4 text-sm font-medium rounded-sm">
                          {log.topic}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatDateTime(log.inserted_at)}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <LinkButton buttonText="Details" onClick={() => setCurrentLog(log)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </ErrorBoundary>
    </section>
  );
}

Logs.propTypes = {
  connection: ConnectionShape.isRequired,
};
