import React from 'react';

export default function ThisActionBadge() {
  return (
    <span style={{ fontSize: 9 }} className="w-full mb-2 inline-flex items-center gap-x-1.5 rounded-sm bg-indigo-100 px-1.5 font-medium border border-indigo-700 text-indigo-700">
      <svg className="size-1.5 fill-indigo-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3" />
      </svg>
      Current Action
    </span>
  );
}
