import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import View from './View';
import Loading from '../../../components/Loading';
import { ErrorContext } from '../../../context/ErrorContext';
import useClient from '../../../hooks/useClient';
import SimpleView from './SimpleView';

export default function ShowPage() {
  const [view, setView] = useState(true);
  const { setServerError } = useContext(ErrorContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const client = useClient('connections');
  const { data: connection, isLoading } = client.get(id);
  const {
    data: connectionTemplate,
    isInitialLoading: isLoadingTemplate,
  } = client.get(connection?.parent_id, { enabled: !!connection?.parent_id });

  const { mutate: onDestroy } = client.destroy(
    id,
    {
      onSuccess: () => navigate('/connections'),
      onError: () => setServerError(true),
    },
  );

  const { mutate: retry } = client.post(
    id,
    'refresh',
    { onSuccess: () => null },
  );

  if (isLoading || isLoadingTemplate) return <Loading />;

  if (view) {
    return (
      <>
        <button onClick={() => setView(!view)}>Toggle View</button>
        <SimpleView connection={connection} />
      </>
    );
  }
  return (
    <>
      <button onClick={() => setView(!view)}>Toggle View</button>
      <View
        connection={connection}
        connectionTemplate={connectionTemplate}
        onDestroy={onDestroy}
        onRetry={retry}
      />
    </>
  );
}
