import React from 'react';
import PropTypes from 'prop-types';
import MultiCheckBox from '../../../../../components/forms/MultiCheckBox';
import ScopeTags from './ScopeTags';
import ConnectionTemplateShape from '../../../../../shapes/ConnectionTemplateShape';
import FormShape from '../../../../../shapes/FormShape';

export default function ScopeInputWrapper({ connectionTemplate, form, editMode }) {
  // We only render scopes for oauth2.0
  if (form.watch('authentication.record_type') !== 'oauth2') return null;

  // Render checkboxes with a cxnTemplate is available with a scope list
  if (connectionTemplate?.authentication?.scope) {
    // Set our current options as an array
    const availableScopes = connectionTemplate?.authentication?.scope?.split(' ') || [];
    return (
      <div className="mt-2">
        <MultiCheckBox
          form={form}
          label="Scope"
          name="api.authentication.scope"
          options={availableScopes.map((v) => ({ key: v }))}
        />
      </div>
    );
  }

  // In any situation w/o edit mode, give the user the ability to add or remove scopes.
  if (!connectionTemplate) return (<ScopeTags form={form} editMode={editMode} />);

  return null;
}

ScopeInputWrapper.propTypes = {
  connectionTemplate: ConnectionTemplateShape,
  form: FormShape.isRequired,
  editMode: PropTypes.bool.isRequired,
};

ScopeInputWrapper.defaultProps = {
  connectionTemplate: null,
};
