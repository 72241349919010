import React from 'react';
import cronstrue from 'cronstrue';
import ActionShape from '../../../../shapes/ActionShape';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import PredicateTree from './PredicateTree';

export default function TriggerCard({ action }) {
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Trigger Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about this action's trigger.</p>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 m-4">
            {
          action.topic
            ? (
              <>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Connection</dt>
                  <dd className="mt-1 text-sm text-gray-900 flex">
                    {connectionAvatar(action.sourceConnection, { size: 'xs', containerSize: 'xs' })}
                    <span className="ml-1">{action.sourceConnection?.name}</span>
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Topic</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {action.topic}
                  </dd>
                </div>
                <div className="col-span-3">
                  <dt className="text-sm font-medium text-gray-500">Additional Filters</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {action.matcher === 'true' ? 'N/A' : <PredicateTree predicate={action.body} />}
                  </dd>
                </div>
              </>
            )
            : (
              <>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Schedule Description</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {action.schedule ? cronstrue.toString(action.schedule) : 'N.A'}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Schedule Cron</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {action.schedule || 'N.A'}
                  </dd>
                </div>
              </>
            )
        }
          </dl>
        </div>
      </ErrorBoundary>
    </section>
  );
}

TriggerCard.propTypes = {
  action: ActionShape.isRequired,
};
