import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GoWorkflow } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/layout/PageHeader';
import DeleteConfirm from '../../../../components/DeleteConfirm';
import WorkflowShape from '../../../../shapes/WorkflowShape';
import General from './General';
import ActionList from './ActionList';
import PageWrapper from '../../../../components/layout/PageWrapper';
import BodyWrapper from '../../../../components/layout/ShowPage/BodyWrapper';
import useMe from '../../../../context/CurrentUserContext';

export default function View({ onDestroy, workflow }) {
  const { allActions } = useMe();
  const actions = allActions.filter((a) => a.workflow_id === workflow.id);

  const navigate = useNavigate();
  // const [showPauseConfirm, setShowPauseConfirm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const buttons = [
    { label: 'Delete', icon: TrashIcon, onClick: () => setShowDeleteModal(true) },
    { label: 'Edit', icon: PencilIcon, onClick: () => navigate('edit') },
    // { label: 'Pause', icon: PlayPauseIcon, onClick: () => setShowPauseConfirm(true) },
    // { label: 'Test', icon: SignalIcon, onClick: () => null },
  ];
  return (
    <PageWrapper>
      <DeleteConfirm
        title="Delete Workflow"
        description="Are you sure you want to permanently delete this automation workflow? All of your log records will remain, but no event payload data will persist."
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDestroy}
      />
      <Header
        description={workflow.name}
        name="Automation Workflow Detail Page"
        buttons={buttons}
        icon={<GoWorkflow className="w-9 h-9 text-indigo-700" />}
      />
      <BodyWrapper>
        <General workflow={workflow} />
        <ActionList workflow={workflow} actions={actions} />
      </BodyWrapper>
    </PageWrapper>
  );
}

View.propTypes = {
  onDestroy: PropTypes.func.isRequired,
  workflow: WorkflowShape.isRequired,
};
