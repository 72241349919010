/* eslint-disable max-len */
import { SignalIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';
import FilterBar from '../../../components/layout/FilterBar/V2FitlerBar';
import PageHeader from '../../../components/layout/PageHeader';
import ItemList from '../../../components/ItemsList';
import useIndexFetchClient, { useIndexButtons } from '../../../hooks/useIndexFetchClient';
import useClient from '../../../hooks/useClient';
import { extractQueryableArray } from '../../../helpers/ArrayHelpers';
import SearchBar from '../../../components/layout/SearchBar';

const config = {
  clientName: 'events',
  resourceName: 'Event',
  description: 'A list of all Events that are occuring in your Source Connections',
  mobileTitle: 'Event',
  icon: <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />,
};

export default function IndexPage() {
  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config);

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);

  const eventIds = extractQueryableArray(indexClient.data, 'id');
  const { data, meta, isInitialLoading: isLoadingLogs } = useClient('logs').list(
    { records: { event_id: eventIds }, page_size: 100, page },
    { enabled: !!eventIds.length, onError: indexClient.onError },
  );
  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    }
  }, [meta?.page]);

  // Find logs to display table line data
  const findLogs = ({ id }) => logs.filter((log) => log.records.event_id === id);
  const items = indexClient.data?.map((i) => ({ ...i, logs: findLogs(i) }));

  // Buttons for Top Bar
  const buttons = useIndexButtons(config.resourceName, indexClient);

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        indexClient={indexClient}
      >
        <FilterForm indexClient={indexClient} />
      </FilterBar>
      <SearchBar
        name="matcher"
        label="Search Events"
        indexClient={indexClient}
        placeholder="Search for events by ID..."
      />
      <ItemList
        body={<TableBody items={items || []} />}
        meta={indexClient.meta}
        header={<TableHeader />}
        isLoading={indexClient.isLoading || isLoadingLogs}
      />
    </div>
  );
}
