/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import ActionShape from '../../../shapes/ActionShape';

export default function StatusBadge({ action }) {
  if (action.status === 'inactive') {
    return (
      <span style={{ fontSize: 9 }} className="mx-2 h-4 inline-flex items-center gap-x-1.5 rounded-sm bg-red-100 px-1.5 font-medium border border-red-700 text-red-700">
        <svg className="size-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
          <circle cx="3" cy="3" r="3" />
        </svg>
        Inactive
      </span>
    );
  }

  return (
    <span style={{ fontSize: 9 }} className="mx-2 h-4 inline-flex items-center gap-x-1.5 rounded-sm bg-teal-100 px-1.5 font-medium border border-teal-700 text-teal-700">
      <svg className="size-1.5 fill-teal-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3" />
      </svg>
      Active
    </span>
  );
}

StatusBadge.propTypes = {
  action: ActionShape.isRequired,
};
