import React from 'react';
import PropTypes from 'prop-types';
import { Viewer, Differ } from 'json-diff-kit';
import LogShape from '../shapes/LogShape';
import { byInsertedAt } from '../helpers/LogHelpers';
import 'json-diff-kit/dist/viewer.css';

// all configs are optional
const differ = new Differ({
  detectCircular: true, // default `true`
  maxDepth: Infinity, // default `Infinity`
  showModifications: true, // default `true`
  arrayDiffMethod: 'lcs', // default `"normal"`, but `"lcs"` may be more useful
});

export default function ModalBody({ onClose, log }) {
  const { updateLogs } = log;
  const sortedUpdateLogs = updateLogs.sort(byInsertedAt);
  const isCurrentLog = (l) => l.id === log.id;
  const idx = sortedUpdateLogs.findIndex(isCurrentLog);

  const prevLog = idx === 0 ? {} : sortedUpdateLogs[idx - 1];
  const diff = differ.diff(prevLog.body || {}, log.body);

  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <div className="flex justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Log Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Request body and response data for this action request.</p>
          </div>
          <button type="button" onClick={onClose} className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="sr-only">Close</span>
            <svg className="size-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      <div className="mx-4 mt-2 rounded border border-gray-300 text-xs">
        <Viewer
          diff={diff} // required
          indent={4} // default `2`
          lineNumbers // default `false`
          highlightInlineDiff // default `false`
          inlineDiffOptions={{
            mode: 'word', // default `"char"`, but `"word"` may be more useful
            wordSeparator: ' ', // default `""`, but `" "` is more useful for sentences
          }}
        />
      </div>
    </div>
  );
}

ModalBody.propTypes = {
  log: LogShape.isRequired,
  onClose: PropTypes.func.isRequired,
};
